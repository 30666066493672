import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Select,
  MenuItem,
  Grid
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQuarterlyDataRange } from "../../services/useGetSetHookQuaterly";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const BjaQuarterlyData = () => {
  const [rangeFilter, setRangeFilter] = useState({
    "start": {
        "reporting_period": "Q1 Jan-Mar",
        "reporting_year": "2024-01-03T17:10:14.495Z"
    },
    "end": {
        "reporting_period": "Q4 Oct-Dec",
        "reporting_year": "2025-01-03T17:10:14.495Z"
    }
});
  const [startPeriod, setStartPeriod] = useState("");
  const [startYear, setStartYear] = useState(null);
  const [endPeriod, setEndPeriod] = useState("");
  const [endYear, setEndYear] = useState(null);


  const handleApplyFilter = () => {
    setRangeFilter({
      start: { reporting_period: startPeriod, reporting_year: startYear },
      end: { reporting_period: endPeriod, reporting_year: endYear },
    });
  };

  const { filteredData } = useQuarterlyDataRange(
    "https://dev-ne-api.cynchealth.org/pdmp/v1/stats/bja/bja_quart_prescriber_count",
    rangeFilter
  );
  const { filteredData: bjaQuartHighMmeCount } = useQuarterlyDataRange(
    "https://dev-ne-api.cynchealth.org/pdmp/v1/stats/bja/bja_quart_high_mme_count",
    rangeFilter
  );
  const { filteredData: bjaQuartDrugThresholdCount } = useQuarterlyDataRange(
    "https://dev-ne-api.cynchealth.org/pdmp/v1/stats/bja/bja_quart_drug_threshold_count",
    rangeFilter
  );
  const { filteredData: bjaQuartScheduleDrugCount } = useQuarterlyDataRange(
    "https://dev-ne-api.cynchealth.org/pdmp/v1/stats/bja/bja_quart_schedule_drug_count",
    rangeFilter
  );

  console.log(filteredData, bjaQuartHighMmeCount, bjaQuartScheduleDrugCount);
  const uniqueMmePeriods = [
    ...new Set(
      bjaQuartHighMmeCount.map(
        (d) => `${d.reporting_period} (${d.reporting_year})`
      )
    ),
  ];

  const getPatientCount = (periodWithYear, ageGroup) => {
    const [reporting_period, reporting_year] = periodWithYear
      .match(/(.*) \((\d{4})\)/)
      .slice(1, 3);

    const entry = bjaQuartHighMmeCount.find(
      (d) =>
        d.reporting_period === reporting_period &&
        d.reporting_year.toString() === reporting_year &&
        d.patient_age_group === ageGroup
    );
    return entry ? entry.patient_count : "-";
  };

  const uniqueDrugPeriods = [
    ...new Set(
      bjaQuartDrugThresholdCount.map(
        (item) => `${item.reporting_period} (${item.reporting_year})`
      )
    ),
  ];

  const getDrugThresholdData = (periodWithYear) => {
    const [reporting_period, reporting_year] = periodWithYear
      .match(/(.*) \((\d{4})\)/)
      .slice(1, 3);

    const entry = bjaQuartDrugThresholdCount.find(
      (item) =>
        item.reporting_period === reporting_period &&
        item.reporting_year.toString() === reporting_year
    );
    return entry ? entry.control_count : [];
  };

  const uniqueScheduleDrugPeriods = [
    ...new Set(
      bjaQuartScheduleDrugCount.map(
        (d) => `${d.reporting_period} (${d.reporting_year})`
      )
    ),
  ];

  const getMedicationData = (period, schedule, ageGroup) => {
    const [reporting_period, reporting_year] = period
      .match(/(.*) \((\d{4})\)/)
      .slice(1, 3);

    const entry = bjaQuartScheduleDrugCount.find(
      (d) =>
        d.reporting_period === reporting_period &&
        d.reporting_year.toString() === reporting_year &&
        d.medication_schedule === schedule &&
        d.patient_age_group === ageGroup
    );
    return entry ? entry.patient_count : "-";
  };

  const toRoman = (num) => {
    const romanMap = [
      ["X", 10],
      ["IX", 9],
      ["V", 5],
      ["IV", 4],
      ["I", 1],
    ];
    let result = "";
    for (const [roman, value] of romanMap) {
      while (num >= value) {
        result += roman;
        num -= value;
      }
    }
    return result;
  };
  const quarterOptions = [
    { value: "Q1 Jan-Mar", label: "Q1 Jan-Mar" },
    { value: "Q2 Apr-Jun", label: "Q2 Apr-Jun" },
    { value: "Q3 Jul-Sep", label: "Q3 Jul-Sep" },
    { value: "Q4 Oct-Dec", label: "Q4 Oct-Dec" },
  ];
  return (
    <div>
      {/* Range Picker */}
   
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <Select
            value={startPeriod}
            onChange={(e) => setStartPeriod(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              Start Period
            </MenuItem>
            {quarterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year"]}
              label="Start Year"
              value={startYear}
              maxDate={new Date()}
              onChange={(newValue) => setStartYear(newValue)}
              renderInput={(params) => <Select {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Select
            value={endPeriod}
            onChange={(e) => setEndPeriod(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              End Period
            </MenuItem>
            {quarterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year"]}
              label="End Year"
              value={endYear}
              maxDate={new Date()}
              onChange={(newValue) => setEndYear(newValue)}
              renderInput={(params) => <Select {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
         sx={{ marginY: 1}}
        > 
          <Button variant="outlined" fullWidth onClick={handleApplyFilter}>
            Apply Filter
          </Button>
        </Grid>
      </Grid>
  

      {/* First Accordion */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Number of Prescribers that wrote at least 1 prescription for a
            controlled substance during the 3-month reporting period
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Period</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Prescriber Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {row.reporting_period} ({row.reporting_year})
                      </TableCell>
                      <TableCell>{row.prescriber_count}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No data available for the selected range.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Second Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Number of Patients with MME greater than 90
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Period</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Youth</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Adult</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueMmePeriods.map((period) => (
                  <TableRow key={period}>
                    <TableCell>{period}</TableCell>
                    <TableCell>{getPatientCount(period, "Youth")}</TableCell>
                    <TableCell>{getPatientCount(period, "Adult")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Third Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Number of Patients with Multiple Prescribers and Pharmacies
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Period</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Control Count
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Threshold A: 5+ Prescribers and 5+ Pharmacies
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Threshold B: 10+ Prescribers and 10+ Pharmacies
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueDrugPeriods.map((periodWithYear) => {
                  const drugThresholdData =
                    getDrugThresholdData(periodWithYear);
                  return drugThresholdData.map((row, index) => (
                    <TableRow key={`${periodWithYear}-${index}`}>
                      {index === 0 && (
                        <TableCell rowSpan={drugThresholdData.length}>
                          {periodWithYear}
                        </TableCell>
                      )}
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.threshold_A ?? "-"}</TableCell>
                      <TableCell>{row.threshold_B ?? "-"}</TableCell>
                    </TableRow>
                  ));
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Fourth Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            Any patients (youth and adults) were prescribed the following
            scheduled drugs (non-liquid) 3-month reporting period?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Period</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Schedule of Medication
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Youth</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Adults</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueScheduleDrugPeriods.map((period) =>
                  ["2", "3", "4"].map((schedule, index) => (
                    <TableRow key={`${period}-${schedule}`}>
                      {index === 0 && (
                        <TableCell rowSpan={3}>{period}</TableCell>
                      )}
                      <TableCell>Schedule {toRoman(schedule)}</TableCell>
                      <TableCell>
                        {getMedicationData(period, schedule, "Youth")}
                      </TableCell>
                      <TableCell>
                        {getMedicationData(period, schedule, "Adult")}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BjaQuarterlyData;
