import React, { useEffect, useMemo } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import BjaMonthlyData from "../components/bjaMetrics/BjaMonthlyData";
import BjaQuarterlyData from "../components/bjaMetrics/BjaQuarterlyData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const BjaMetrics = () => {
  const [value, setValue] = React.useState(0);

  // Memoize the tabs array to prevent re-creation on every render
  const tabs = useMemo(
    () => [
      { label: "BJA Monthly Data", anchor: "bja-monthly-data" },
      { label: "BJA Quaterly Data", anchor: "bja-quaterly-data" },
    ],
    []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newPath = tabs[newValue].anchor;
    window.location.hash = newPath;
  };

  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor) {
      const selectedTab = anchor.substring(1); // Remove the '#' symbol
      const tabIndex = tabs.findIndex((tab) => tab.anchor === selectedTab);
      if (tabIndex !== -1) {
        setValue(tabIndex);
      }
    }
  }, [tabs]);

  return (
    <div className="login-page">
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", height: "auto" }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            border: 1,
            borderColor: "divider",
            borderRadius: "50px",
            boxShadow: "0px 1px 5px #aaaaaa",
            [`& .${tabsClasses.scrollButtons}`]: {
              boxShadow: "1px 10px 20px #aaaaaa",

              "&.Mui-disabled": { display: "none" },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              sx={{
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                },
                "&:not(.Mui-selected)": {
                  "&:hover": {
                    backgroundColor: "primary.light",
                    opacity: 1,
                  },
                },
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.anchor === "bja-monthly-data" && <BjaMonthlyData />}
            {tab.anchor === "bja-quaterly-data" && <BjaQuarterlyData />}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};

export default BjaMetrics;
